import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import dynamic from 'next/dynamic'
import { ChatRoom } from './ChatRoom'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import {
  ModalCardChatActions,
  ModalCardTitleDisplay
} from '@/components/organisms/modal/ModalCardHeader'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { useChatRoomDocument } from '@/entity/chat/useChatRoomDocument'
import { useCommunityTicket } from '@/entity/community/useCommunityTicket'
import { useCompetitionTicket } from '@/entity/competition/useCompetitionTicket'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'

export const ChatInstance = ({
  id,
  focused,
  onActivate,
  onClose,
  autoFocus
}: {
  id: string
  focused: boolean
  onActivate: () => void
  onClose: () => void
  autoFocus: boolean
}) => {
  const { room } = useChatRoomDocument({
    idOrKey: id
  })

  return (
    <Box
      sx={{
        height: '100%',
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Stack
        component={Paper}
        elevation={6}
        onClick={onActivate}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        py={1}
        px={2}
        spacing={1}
        sx={theme => ({
          cursor: 'pointer',
          borderRadius: 1,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        })}
      >
        <ModalCardTitleDisplay
          title={
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              maxWidth='100%'
              overflow='hidden'
            >
              {focused ? (
                <RcAvatar
                  src={room?.image}
                  sx={{ width: 28, height: 28 }}
                  variant='rounded'
                >
                  {room?.name?.charAt(0)?.toLocaleUpperCase()}
                </RcAvatar>
              ) : null}

              <EllipsisBox lineClamp={2} breakAll={false}>
                <Tooltip title={room?.name}>
                  <Typography
                    variant={focused ? 'body0' : 'body2'}
                    flexGrow={1}
                    color='text.primary'
                    maxWidth={focused ? 220 : 140}
                  >
                    {room?.name || <RcTrans i18nKey='shared.loading' />}
                  </Typography>
                </Tooltip>
              </EllipsisBox>
            </Stack>
          }
        />
        <Stack direction='row'>
          <ModalCardChatActions />
          <RcIconButton
            icon={['fal', 'times']}
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}
            size={'small'}
          />
        </Stack>
      </Stack>

      {focused ? (
        <RcSuspense>
          <ChatRoom
            chatId={id}
            onNavigate={onActivate}
            onLeave={onClose}
            allowExpand
            autoFocus={autoFocus}
          />
        </RcSuspense>
      ) : null}
    </Box>
  )
}

export const CommunityTicketHeader = ({ ticketId }: { ticketId: number }) => {
  const {
    ticket,
    close,
    read: { isValidating }
  } = useCommunityTicket({
    idOrKey: ticketId
  })
  return (
    <TicketHeader ticket={ticket} close={close} isValidating={isValidating} />
  )
}

export const CompetitionTicketHeader = ({ ticketId }: { ticketId: number }) => {
  const {
    ticket,
    close,
    read: { isValidating }
  } = useCompetitionTicket({
    idOrKey: ticketId
  })
  return (
    <TicketHeader ticket={ticket} close={close} isValidating={isValidating} />
  )
}

const TicketHeader = ({
  ticket,
  close,
  isValidating
}: {
  ticket?: { id?: number; description?: string; closed?: boolean }
  close: (id: number) => Promise<any>
  isValidating: any
}) => {
  return (
    <Paper sx={{ borderRadius: 0, p: 4 }}>
      <Stack
        direction='row'
        spacing={3}
        justifyContent='space-between'
        alignItems='center'
      >
        <MarkdownDisplay markdown={ticket?.description} paragraph={false} />
        {isValidating ? null : ticket?.closed ? (
          <RcButton disabled variant='outlined' size='small'>
            <RcTrans i18nKey='contact:resolved-button' />
          </RcButton>
        ) : (
          <RcButton onClick={() => close(ticket?.id!)} size='small'>
            <RcTrans i18nKey='contact:mark-as-resolved-button' />
          </RcButton>
        )}
      </Stack>
    </Paper>
  )
}
